import './App.css';
import Roulette from './Roulette';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Roulette/>
      </header>
    </div>
  );
}

export default App;
