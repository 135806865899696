import React, { useState } from 'react'
import { Wheel } from 'react-custom-roulette'

const data = [
  { option: 'Thang', style: { backgroundColor: 'red', textColor: 'black' } },
  { option: 'Khang', style: { backgroundColor: 'orange', textColor: 'red' } },
  { option: 'Chuong',  style: { backgroundColor: 'yellow', textColor: 'black' } },
  { option: 'Nam', style: { backgroundColor: 'blue', textColor: 'black' } },
  { option: 'Nxx', style: { backgroundColor: 'green', textColor: 'red' } },
  { option: 'Hoa',  style: { backgroundColor: 'purple', textColor: 'black' } },
  { option: 'Hoang',  style: { backgroundColor: 'black', textColor: 'white' } },
  { option: 'Nguyen',  style: { backgroundColor: 'red', textColor: 'white' } },
]

const Roulette = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length)
    setPrizeNumber(newPrizeNumber)
    setMustSpin(true)
  }

  return (
    <>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}

        onStopSpinning={() => {
          setMustSpin(false);
        }}
      />
      <button onClick={handleSpinClick}>SPIN</button>
    </>
  )
}

export default Roulette;